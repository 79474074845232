<template>
  <div class="card card-custom gutter-b h-100">
    <!--  header-->
    <div class="card-header">
      <div class="card-title">
        Danh sách yêu cầu
      </div>
      <div class="card-title">
        <button type="button" class="btn btn-success" @click="exportTicketCsvc()">Export</button>
      </div>
    </div>
    <!--  body-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Tìm yêu cầu</h4>
          <el-input placeholder="Nhập tên CSVC, mô tả chi tiết" v-model="query_find.nameRequest" @input="getTicketCSVC()"></el-input>
        </div>
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Người xử lý</h4>
          <el-input placeholder="Chọn người xử lý" v-model="query_find.personHandle" @input="getTicketCSVC()"></el-input>
        </div>
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Trạng thái</h4>
          <el-select v-model="query_find.status" placeholder="Chọn trạng thái" style="width: 100%" @change="getTicketCSVC()" clearable filterable >
            <el-option
                v-for="item in LIST_STATUS"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 col-sm-12 mb-3" >
          <h4 style="font-size: 13px">Đánh giá</h4>
          <el-select v-model="query_find.rate" placeholder="Đánh giá" style="width: 100%" @change="getTicketCSVC()" clearable multiple filterable>
            <el-option
                v-for="item in LIST_RATE"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Thời gian gửi yêu cầu</h4>
          <el-date-picker
              @change="getTicketCSVC()"
              v-model="query_find.timeSentRequest"
              type="daterange"
              placeholder="Chọn thời gian"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
              format="yyyy/MM/dd"
              style="width: 100%"
              value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Địa điểm</h4>
          <el-select v-model="query_find.location" placeholder="Chọn trạng thái" style="width: 100%" @change="getTicketCSVC()" clearable multiple>
            <el-option
                v-for="item in listBranch"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <h1 class="mt-2" style="font-size: 14px">Tổng số yêu cầu: <span style="color: #00aff0">{{total}}</span></h1>
        <h1 class="mt-4" style="font-size: 14px">Tổng tiền chi: <span style="color: #00aff0">{{totalPrice}}</span></h1>
      </div>
      <div class="mt-6 table-responsive">
        <table class="table table-bordered text-center table-vertical-center ">
          <thead>
          <tr style="background-color: #F8F8F8 ; font-size: 13px">
            <th class="text-center">ID</th>
            <th>Cơ sở vật chất</th>
            <th>Mô tả chi tiết yêu cầu</th>
            <th>Trạng thái</th>
            <th>Người phụ trách</th>
            <th>Thời gian gửi yêu cầu</th>
            <th>Hành động</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="listRequest.length === 0">
            <td colspan="7">
              <i class="el-icon-loading"></i>
            </td>
          </tr>
          <tr v-if="listRequest.length === 0 && !loading">
            <td colspan="7">
              Không có dữ liệu.
            </td>
          </tr>
          <tr v-for="(request,index) in listRequest" :key="index">
            <td>{{ request.id }}</td>
            <td>{{ request.name_ticket }}</td>
            <td  class="text-left">{{checkLoadMoreDescription(request.description)}}</td>
            <td>
              <button type="button" :class="request.colorStatus" class="btn-sm">{{ request.textStatus }}</button>
            </td>
            <td>{{ request?.user_handle?.name?? '-' }}</td>
            <td>{{ request.created_at }}</td>
            <td>
              <button class="btn btn-xs btn-icon mr-2 btn-outline-primary" title="Xoá lịch trình" @click="requestDetails(request.id)">
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-0 d-flex flex-row-reverse mr-10">
      <p class="mb-0"></p>
    </div>
    <div class="edu-paginate mx-auto text-center mt-10 ">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
          :page-class="'page-item'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {
  EXPORT_TICKET_CSVC,
  GET_LIST_BRANCH_EDUTALK_HEADQUARTER,
  GET_TICKET_CSVC
} from "@/core/services/store/csvc/csvc.module";
import {COLOR_STATUS, HOAN_THANH, LIST_RATE, LIST_STATUS, TEXT_STATUS} from "@/core/option/csvcOption";
import moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {exportExcel} from "@/core/filters";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "InfrastructureList",
  components: {DatePicker},
  data() {
    return {
      page: 1,
      last_page: 0,
      total: 0,
      listRequest: [],
      query_find: {
        nameRequest: null,
        personHandle: null,
        status: null,
        timeSentRequest: [],
        rate: null,
        location: null
      },
      LIST_STATUS: LIST_STATUS,
      totalPrice: null,
      HOAN_THANH: HOAN_THANH,
      LIST_RATE: LIST_RATE,
      listBranch: [],
      loading: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cơ sở vật chất", route: "" },
      { title: "Danh sách yêu cầu"},
    ]);
    await this.getQueryFilter()
    await this.getListBranchEdutalkHeadquarter()
    await this.getTicketCSVC()
  },
  methods: {
    requestDetails(id) {
      router.push({path: `/infrastructure/detail/${id}`})
    },
    clickCallback(obj) {
      this.page = obj;
      this.getTicketCSVC()
    },
    getTicketCSVC() {
      let payload = {
        page: this.page,
        keyword: this.query_find?.nameRequest,
        user_handle: this.query_find?.personHandle,
        status: this.query_find?.status,
        branch_id: this.query_find?.location,
        rate: this.query_find?.rate
      }
      if(this.query_find.timeSentRequest){
        payload.date_start = this.query_find?.timeSentRequest[0]
        payload.date_end = this.query_find?.timeSentRequest[1]
      }
      this.$store.dispatch(GET_TICKET_CSVC,payload).then((data) => {
        let responseData = data?.data?.data
        this.listRequest = responseData.data
        this.filterTicketCSVC()
        this.listRequest = this.listRequest?.map((request) => {
          return {
            ...request,
            textStatus: TEXT_STATUS.get(request?.status),
            colorStatus: COLOR_STATUS.get(request?.status),
            created_at: moment(request?.created_at).format('HH:mm DD-MM-YYYY')
          }
        })
        this.total = responseData.total;
        this.page = responseData.page;
        this.last_page = responseData.last_page;
        this.totalPrice = data?.data?.price?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) ?? 0;
        this.loading = false
      })
    },
    getQueryFilter() {
       this.query_find.nameRequest = this.$route.query?.keyword;
       this.query_find.personHandle = this.$route.query?.user_handle;
       this.query_find.status = this.$route.query.status ? +this.$route.query?.status : null;
       this.query_find.location = this.$route.query.branch_id && this.$route.query.branch_id?.map(e=>+e);
       this.query_find.rate = this.$route.query.rate && this.$route.query.rate?.map(e=>+e);
       this.query_find.timeSentRequest = this.$route.query?.date_start && this.$route.query?.date_end ? [this.$route.query?.date_start, this.$route.query?.date_end] : [];
    },
    exportTicketCsvc(){
      let payload = {
        page: this.page,
        keyword: this.query_find?.nameRequest,
        user_handle: this.query_find?.personHandle,
        status: this.query_find?.status,
        date_time: this.query_find?.timeSentRequest
      }
      this.$store.dispatch(EXPORT_TICKET_CSVC,payload).then((data)=>{
        const HEADER_ROW_EXPORT_TICKET_CSVC= [
          {
            key: 'id',
            value: 'ID',
          },
          {
            key: 'name_ticket',
            value: 'Cơ sở vật chất',
          },
          {
            key: 'description',
            value: 'Mô tả chi tiết yêu cầu',
          },
          {
            key: 'status',
            value: 'Trạng thái',
          },
          {
            key: 'user_handle',
            value: 'Người phụ trách',
          },

          {
            key: 'created_at',
            value: 'Thời gian gửi yêu cầu',
          },
          {
            key: 'date_reception',
            value: 'Thời gian tiếp nhận',
          },
          {
            key: 'solution',
            value: 'Phương án xử lý',
          },
          {
            key: 'date_time',
            value: 'Thời gian dự kiến hoàn thành',
          },
          {
            key: 'date_success_at',
            value: 'Thời gian hoàn thành',
          },
          {
            key: 'price',
            value: 'Chi phí',
          },
          {
            key: 'description_success',
            value: 'Ghi chú',
          },
          {
            key: 'rate_star',
            value: 'Đánh giá',
          },
          {
            key: 'branch_name',
            value: 'Địa điểm cần bổ sung, sửa chữa',
          },
        ]
        exportExcel(HEADER_ROW_EXPORT_TICKET_CSVC, data.data, 'Danh sách yêu cầu.xlsx',false)
      })
    },
    checkLoadMoreDescription(description){
      if(description?.length > 50){
        return description.slice(0,50)+'...'
      }
      return  description
    },
    getListBranchEdutalkHeadquarter(){
      this.$store.dispatch(GET_LIST_BRANCH_EDUTALK_HEADQUARTER).then((data)=>{
        this.listBranch = data.data
      })
    },
    filterTicketCSVC() {
      this.$router.push({
        query: {
          'keyword': this.query_find?.nameRequest,
          'user_handle': this.query_find?.personHandle,
          'status': this.query_find?.status,
          'branch_id': this.query_find?.location,
          'rate': this.query_find?.rate,
          'date_start': this.query_find?.timeSentRequest[0],
          'date_end': this.query_find?.timeSentRequest[1],
        }
      })
    }
  }
}
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}

.mx-input {
  min-height: 38px !important;
}
</style>